import { MouseEvent } from "react";

import { coinsSelectors, coinsThunks } from "store/coins";

import useDispatch from "hooks/redux/use-dispatch";
import useSelector from "hooks/redux/use-selector";

export const useConnect = (id: Coin["letterId"]) => {
  const isFavorite = useSelector(
    state => coinsSelectors.selectById(state, id)?.isFavorite,
  );
  const dispatch = useDispatch();

  const onSetFavorite = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(coinsThunks.toggleFavorite(id));
  };

  return {
    onSetFavorite,
    isFavorite,
  };
};
