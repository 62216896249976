import { FC } from "react";

import StarFilledIcon from "icons/star-filled.svg?react";
import StarOutlinedIcon from "icons/star-outlined.svg?react";

import Button, { Themes, Sizes } from "basics/button";

import { useConnect } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
  themeName?: Themes;
  size?: Sizes;
  id: Coin["letterId"];
}

const PinButton: FC<Props> = ({ id, ...props }) => {
  const { onSetFavorite, isFavorite } = useConnect(id);

  return (
    <Button {...props} onClick={onSetFavorite} isIconOnly>
      {isFavorite ? (
        <StarFilledIcon className={classes.icon} />
      ) : (
        <StarOutlinedIcon className={classes.icon} />
      )}
    </Button>
  );
};

export default PinButton;
